import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Multiselect from "react-select";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CUSTOMDROPDOWNSTYLE, FORMIKERROR } from "../../utils/constants";
import DatePicker from "react-multi-date-picker";
import Enums from "../../utils/enums";
import { useTranslation } from "react-i18next";

const AddUser = (props) => {
  const { ROLE, PREFERRED_PAYMENT, COMISSION, DEALER_TYPE } = Enums();
  const { t } = useTranslation("common");

  const {
    formik,
    city,
    selectPaidList,
    selectRole,
    selectCity,
    options,
    update,
    vatNumber,
    handleVAT,
    businessUsers,
    selectBusinessUser,
    handleChangePhone,
    newRole,
    openCity,
    handleOpenCity,
    handleCloseCity,
    openRole,
    handleOpenRole,
    handleCloseRole,
    openBusinessUser,
    handleOpenBusinessUser,
    handleCloseBusinessUser,
    openDealerType,
    handleOpenDealerType,
    handleCloseDealerType,
    openPaymentType,
    handleOpenPaymentType,
    handleClosePaymentType,
    selectServiceFee,
    handleOpenServiceFee,
    handleCloseServiceFee,
    openServiceFee,
    handleExpirationDate,
    handleInvoiceCode,
    handlePercentage,
    handlefileChange,
    selectedImage,
    cityOptions,
    stateOptions,
    selectState,
    openState,
    handleOpenState,
    handleCloseState,
    setSearch,
    loadMore,
    visibleOptions,
    filteredOptions,
    handleCAPChange,
    cashbackInAmount,
    changeCashback,
    handleWalletAmountChange,
    showReason,
  } = props;

  return (
    <Modal
      {...props}
      // size={
      //   options.value === "CHILD_USER" || newRole === "CHILD_USER"
      //     ? "large"
      //     : "xl"
      // }
      // size="lg"
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5 className="tx-semibold mt-2 ms-auto tx-uppercase">
          {update
            ? t("update") + " " + t("users")
            : t("add") + " " + t("users")}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <Row className="row-sm">
            {/* <Col lg={6}> */}
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("username")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.username}
                onChange={formik.handleChange}
                name="username"
                placeholder={t("username")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.username && formik.touched.username ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.username}
                </Form.Label>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("surname")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.surname}
                onChange={formik.handleChange}
                name="surname"
                placeholder={t("surname")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.surname && formik.touched.surname ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.surname}
                </Form.Label>
              ) : null}
            </Form.Group>
            {/* </Col>
            <Col lg={6}> */}
            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("email")} :</Form.Label>
              <Form.Control
                type="text"
                value={formik.values.email}
                onChange={formik.handleChange}
                name="email"
                placeholder={t("email")}
                style={{ borderColor: "#d3d3de" }}
              />
              {formik.errors.email && formik.touched.email ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.email}
                </Form.Label>
              ) : null}
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label className="text-dark">{t("role")} :</Form.Label>
              {!newRole && (
                <Multiselect
                  classNamePrefix="Select2"
                  options={ROLE}
                  singleSelect
                  placeholder={t("role")}
                  displayValue="key"
                  name="role"
                  menuIsOpen={openRole}
                  onMenuOpen={handleOpenRole}
                  onMenuClose={handleCloseRole}
                  value={ROLE.find(
                    (option) => option.value === formik.values.role
                  )}
                  onChange={selectRole}
                  styles={CUSTOMDROPDOWNSTYLE}
                  // isDisabled={newRole ? true : false}
                />
              )}
              {newRole && (
                <Form.Control
                  type="text"
                  value={formik.values.role}
                  name="role"
                  placeholder={t("role")}
                  disabled
                  style={{ borderColor: "#d3d3de" }}
                />
              )}
              {formik.errors.role && formik.touched.role ? (
                <Form.Label style={FORMIKERROR}>
                  {formik.errors.role}
                </Form.Label>
              ) : null}
            </Form.Group>
            {/* </Col> */}

            {(options.value === "DEALER" ||
              newRole === "DEALER" ||
              options.value === "BUSINESS_USER" ||
              newRole === "BUSINESS_USER") && (
              <>
                {/* <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("companyname")} :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    name="companyName"
                    placeholder={t("companyname")}
                    style={{ borderColor: "#d3d3de" }}
                  />
                  {formik.errors.companyName && formik.touched.companyName ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.companyName}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col> */}
              </>
            )}

            {(options.value === "DEALER" || newRole === "DEALER") && (
              <>
                {/* <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("type") + " " + t("dealer")} :
                  </Form.Label>
                  <Multiselect
                    classNamePrefix=""
                    options={DEALER_TYPE.map((option) => ({
                      value: option.value,
                      label: t(option.label),
                    }))}
                    singleSelect
                    placeholder={t("type") + " " + t("dealer")}
                    displayValue="key"
                    name="dealerType"
                    // defaultInputValue={newType}
                    menuIsOpen={openDealerType}
                    onMenuOpen={handleOpenDealerType}
                    onMenuClose={handleCloseDealerType}
                    value={DEALER_TYPE.find(
                      (option) => option.value === formik.values.dealerType
                    )}
                    onChange={selectPaidList}
                    styles={CUSTOMDROPDOWNSTYLE}
                  />
                  {formik.errors.dealerType && formik.touched.dealerType ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.dealerType}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col> */}
              </>
            )}

            {(formik.values.role === "BUSINESS_USER" ||
              formik.values.role === "USER") && (
              <>
                {/* <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("walletamount")} :
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={formik.values.walletAmount}
                    onChange={handleWalletAmountChange}
                    name="walletAmount"
                    placeholder={t("walletamount")}
                    style={{ borderColor: "#d3d3de" }}
                  />
                  {formik.errors.walletAmount && formik.touched.walletAmount ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.walletAmount}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col> */}

                {showReason && (
                  // <Col lg={6}>
                  <Form.Group className="form-group">
                    <Form.Label className="text-dark">
                      {t("walletamountreason")} :
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={formik.values.walletAmountReason}
                      onChange={formik.handleChange}
                      name="walletAmountReason"
                      placeholder={t("walletamountreason")}
                      style={{ borderColor: "#d3d3de" }}
                    />
                    {formik.errors.walletAmountReason &&
                    formik.touched.walletAmountReason ? (
                      <Form.Label style={FORMIKERROR}>
                        {formik.errors.walletAmountReason}
                      </Form.Label>
                    ) : null}
                  </Form.Group>
                  // </Col>
                )}
              </>
            )}

            {formik.values.dealerType === "PAID" && (
              // <Col lg={6}>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">
                  {t("rechargelimit")} :
                </Form.Label>
                <Form.Control
                  type="number"
                  value={formik.values.rechargeLimit}
                  onChange={formik.handleChange}
                  name="rechargeLimit"
                  placeholder={t("rechargelimit")}
                  style={{ borderColor: "#d3d3de" }}
                />
                {formik.errors.rechargeLimit && formik.touched.rechargeLimit ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.rechargeLimit}
                  </Form.Label>
                ) : null}
              </Form.Group>
              // </Col>
            )}

            {(options.value === "CHILD_USER" || newRole === "CHILD_USER") && (
              <>
                {/* <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("childuserpassword")} :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={formik.values.childUserPassword}
                    onChange={formik.handleChange}
                    name="childUserPassword"
                    placeholder={t("childuserpassword")}
                    style={{ borderColor: "#d3d3de" }}
                  />
                  {formik.errors.childUserPassword &&
                  formik.touched.childUserPassword ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.childUserPassword}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col> */}

                {/* <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("businessuser")} :
                  </Form.Label>
                  <Multiselect
                    classNamePrefix="Select2"
                    options={businessUsers}
                    // defaultInputValue={newBusinessPartner}
                    singleSelect
                    placeholder={t("businessuser")}
                    displayValue="key"
                    name="parentUserId"
                    menuIsOpen={openBusinessUser}
                    onMenuOpen={handleOpenBusinessUser}
                    onMenuClose={handleCloseBusinessUser}
                    value={businessUsers.find(
                      (option) => option.value === formik.values.parentUserId
                    )}
                    onChange={selectBusinessUser}
                    styles={CUSTOMDROPDOWNSTYLE}
                  />
                  {formik.errors.parentUserId && formik.touched.parentUserId ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.parentUserId}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col> */}
              </>
            )}

            {(options.value === "DEALER" ||
              options.value === "BUSINESS_USER" ||
              newRole === "DEALER" ||
              newRole === "BUSINESS_USER") && (
              // <Col lg={6}>
              <Form.Group className="form-group">
                <Form.Label className="text-dark">
                  {t("eleinvoicecode")} :
                </Form.Label>
                <Form.Control
                  type="text"
                  value={formik.values.einvoicing}
                  onChange={handleInvoiceCode}
                  name="einvoicing"
                  placeholder={t("eleinvoicecode")}
                  style={{ borderColor: "#d3d3de" }}
                />
                {formik.errors.einvoicing && formik.touched.einvoicing ? (
                  <Form.Label style={FORMIKERROR}>
                    {formik.errors.einvoicing}
                  </Form.Label>
                ) : null}
              </Form.Group>
              // </Col>
            )}
            {(options.value === "DEALER" ||
              options.value === "BUSINESS_USER" ||
              options.value === "USER" ||
              newRole === "DEALER" ||
              newRole === "BUSINESS_USER" ||
              newRole === "USER") && (
              <>
                {/* <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("mobilenumber")} :
                  </Form.Label>
                  <PhoneInput
                    country={"it"}
                    value={`${formik.values.countryCode} ${formik.values.phoneNo}`}
                    onChange={handleChangePhone}
                    inputStyle={{ width: "inherit" }}
                  />

                  {formik.errors.phoneNo && formik.touched.phoneNo ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.phoneNo}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col>
                <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("taxcode")} :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={formik.values.taxCode}
                    onChange={formik.handleChange}
                    name="taxCode"
                    placeholder={t("taxcode")}
                    style={{ borderColor: "#d3d3de" }}
                  />
                  {formik.errors.taxCode && formik.touched.taxCode ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.taxCode}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col> */}
                {newRole !== "USER" && (
                  // <Col lg={6}>
                  <Form.Group className="form-group">
                    <Form.Label className="text-dark">
                      {t("vatnumber")} :
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={vatNumber}
                      onChange={handleVAT}
                      name="vatNumber"
                      placeholder={t("vatnumber")}
                      style={{ borderColor: "#d3d3de" }}
                    />
                    {formik.errors.vatNumber && formik.touched.vatNumber ? (
                      <Form.Label style={FORMIKERROR}>
                        {formik.errors.vatNumber}
                      </Form.Label>
                    ) : null}
                  </Form.Group>
                  // </Col>
                )}
                {/* <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("streetaddress")} :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    name="address"
                    placeholder={t("streetaddress")}
                    style={{ borderColor: "#d3d3de" }}
                  />
                  {formik.errors.address && formik.touched.address ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.address}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col>
                <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">{t("city")} :</Form.Label>
                  {/* <Multiselect
                    classNamePrefix="Select2"
                    options={cityOptions}
                    singleSelect
                    placeholder={t("city")}
                    displayValue="key"
                    name="town"
                    menuIsOpen={openCity}
                    onMenuOpen={handleOpenCity}
                    onMenuClose={handleCloseCity}
                    value={cityOptions.find(
                      (option) => option.label === formik.values.town
                    )}
                    onChange={selectCity}
                    styles={CUSTOMDROPDOWNSTYLE}
                  /> */}
                  <Select
                    classNamePrefix="Select2"
                    id="town"
                    name="town"
                    displayValue="town" // Update to the correct property for display value
                    placeholder="Select a city"
                    singleSelect
                    options={filteredOptions.slice(0, visibleOptions)}
                    menuIsOpen={openCity}
                    onMenuOpen={handleOpenCity}
                    onMenuClose={handleCloseCity}
                    onMenuScrollToBottom={loadMore}
                    onChange={selectCity}
                    value={cityOptions.find(
                      (option) => option.label === formik.values.town
                    )}
                    onInputChange={(inputValue) => setSearch(inputValue)}
                  />
                  {/* <Form.Control
                    type="text"
                    value={formik.values.town}
                    onChange={formik.handleChange}
                    name="town"
                    placeholder={t("city")}
                    style={{ borderColor: "#d3d3de" }}
                  /> */}
                  {formik.errors.town && formik.touched.town ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.town}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col>
                <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("province")} :
                  </Form.Label>
                  <Select
                    id="province"
                    name="province"
                    options={stateOptions}
                    menuIsOpen={openState}
                    onMenuOpen={handleOpenState}
                    onMenuClose={handleCloseState}
                    value={stateOptions.find(
                      (option) => option?.value === formik?.values?.province
                    )}
                    placeholder={t("province")}
                    onChange={selectState}
                  />
                  {formik.errors.province && formik.touched.province ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.province}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col>
                <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("pincode")} :
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={formik.values.cap}
                    onChange={(e) => handleCAPChange(e)}
                    name="cap"
                    placeholder={t("pincode")}
                    style={{ borderColor: "#d3d3de" }}
                  />
                  {formik.errors.cap && formik.touched.cap ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.cap}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col> */}
              </>
            )}
            {(options.value === "BUSINESS_USER" ||
              newRole === "BUSINESS_USER") && (
              <>
                {/* <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("childuserlimit")} :
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={formik.values.childUserLimit}
                    onChange={formik.handleChange}
                    name="childUserLimit"
                    placeholder={t("childuserlimit")}
                    style={{ borderColor: "#d3d3de" }}
                  />
                  {formik.errors.childUserLimit &&
                  formik.touched.childUserLimit ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.childUserLimit}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col>
                <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">{t("pec")} :</Form.Label>
                  <Form.Control
                    type="string"
                    value={formik.values.pec}
                    onChange={formik.handleChange}
                    name="pec"
                    placeholder={t("pec")}
                    style={{ borderColor: "#d3d3de" }}
                  />
                  {formik.errors.pec && formik.touched.pec ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.pec}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col>
                <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("monthlyfeeprice")} :
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={formik.values.monthlyFeePrice}
                    onChange={formik.handleChange}
                    name="monthlyFeePrice"
                    placeholder={t("monthlyfeeprice")}
                    style={{ borderColor: "#d3d3de" }}
                  />
                  {formik.errors.monthlyFeePrice &&
                  formik.touched.monthlyFeePrice ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.monthlyFeePrice}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col>
                <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("expirationdate")} :
                  </Form.Label>
                  <DatePicker
                    calendarPosition="bottom-left"
                    name="expiration"
                    value={formik.values.expiration}
                    onChange={(date) => handleExpirationDate(date)}
                    format={"DD/MM/YYYY"}
                    style={{
                      width: "inherit",
                      height: "38px",
                      borderColor: "#D3D3DE",
                    }}
                    placeholder={t("expirationdate")}
                    minDate={new Date()}
                  />
                  {formik.errors.expiration && formik.touched.expiration ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.expiration}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col>
                <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("servicefeeapplied")} :
                  </Form.Label>
                  <Multiselect
                    classNamePrefix="Select2"
                    options={COMISSION}
                    menuIsOpen={openServiceFee}
                    onMenuOpen={handleOpenServiceFee}
                    onMenuClose={handleCloseServiceFee}
                    value={COMISSION.find(
                      (option) =>
                        option.value === formik.values.serviceFeeApplied
                    )}
                    singleSelect
                    placeholder={t("servicefeeapplied")}
                    displayValue="key"
                    style={{
                      width: "inherit",
                      height: "38px",
                      borderColor: "#D3D3DE",
                    }}
                    name="serviceFeeApplied"
                    onChange={selectServiceFee}
                    // styles={{ borderColor: "#D3D3DE" }}
                    styles={CUSTOMDROPDOWNSTYLE}
                  />
                  {formik.errors.serviceFeeApplied &&
                  formik.touched.serviceFeeApplied ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.serviceFeeApplied}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col> */}
              </>
            )}
            {(options.value === "USER" || newRole === "USER") && (
              <>
                {/* <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("servicefeeapplied")} :
                  </Form.Label>
                  <Multiselect
                    classNamePrefix="Select2"
                    options={COMISSION}
                    menuIsOpen={openServiceFee}
                    onMenuOpen={handleOpenServiceFee}
                    onMenuClose={handleCloseServiceFee}
                    value={COMISSION.find(
                      (option) =>
                        option.value === formik.values.serviceFeeApplied
                    )}
                    singleSelect
                    placeholder={t("servicefeeapplied")}
                    displayValue="key"
                    style={{
                      width: "inherit",
                      height: "38px",
                      borderColor: "#D3D3DE",
                    }}
                    name="serviceFeeApplied"
                    onChange={selectServiceFee}
                    // styles={{ borderColor: "#D3D3DE" }}
                    styles={CUSTOMDROPDOWNSTYLE}
                  />
                  {formik.errors.serviceFeeApplied &&
                  formik.touched.serviceFeeApplied ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.serviceFeeApplied}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col> */}
              </>
            )}
            {(options.value === "BUSINESS_USER" ||
              newRole === "BUSINESS_USER" ||
              options.value === "USER" ||
              newRole === "USER") && (
              <>
                {/* <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("cashbackamount")} :
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={formik.values.cashbackAmount}
                    onChange={formik.handleChange}
                    name="cashbackAmount"
                    placeholder={t("cashbackAmounts")}
                    style={{ borderColor: "#d3d3de" }}
                  />
                  {formik.errors.cashbackAmount &&
                  formik.touched.cashbackAmount ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.cashbackAmount}
                    </Form.Label>
                  ) : null}
                </Form.Group> */}
                {/* <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {cashbackInAmount
                      ? t("cashbackamount")
                      : t("cashbacamountinpr")}{" "}
                    :
                  </Form.Label>
                  <div class="form-group">
                    <div class="input-group">
                      <Form.Control
                        type="number"
                        value={
                          cashbackInAmount
                            ? formik.values.cashbackAmount
                            : formik.values.cashbackAmountInPr
                        }
                        onChange={formik.handleChange}
                        name={
                          cashbackInAmount
                            ? "cashbackAmount"
                            : "cashbackAmountInPr"
                        }
                        placeholder={
                          cashbackInAmount
                            ? t("cashbackamount")
                            : t("cashbacamountinpr")
                        }
                        style={{ borderColor: "#D3D3DE" }}
                      />
                      <span class="input-group-btn">
                        <select class="form-control" onChange={changeCashback}>
                          <option value="$">$</option>
                          <option value="%">%</option>
                        </select>
                      </span>
                    </div>
                  </div>
                  {(formik.errors.cashbackAmount ||
                    formik.errors.cashbackAmountInPr) &&
                  (formik.touched.cashbackAmount ||
                    formik.touched.cashbackAmountInPr) ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.cashbackAmount}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col> */}
              </>
            )}
            {(options.value === "DEALER" || newRole === "DEALER") && (
              <>
                {/* <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("percentageofearnings")} :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={formik.values.percentageOfEarning}
                    onChange={handlePercentage}
                    name="percentageOfEarning"
                    placeholder={t("percentageofearnings")}
                    style={{ borderColor: "#d3d3de" }}
                  />
                  {formik.errors.percentageOfEarning &&
                  formik.touched.percentageOfEarning ? (
                    <Form.Label style={FORMIKERROR}>
                      {formik.errors.percentageOfEarning}
                    </Form.Label>
                  ) : null}
                </Form.Group>
                {/* </Col>
                <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("preferredpaymenttype")} :
                  </Form.Label>
                  <Multiselect
                    classNamePrefix="Select2"
                    options={PREFERRED_PAYMENT.map((option) => ({
                      value: option.value,
                      label: t(option.label),
                    }))}
                    singleSelect
                    placeholder={t("preferredpaymenttype")}
                    displayValue="key"
                    name="preferredPaymentType"
                    // defaultInputValue={newPaymentType}
                    menuIsOpen={openPaymentType}
                    onMenuOpen={handleOpenPaymentType}
                    onMenuClose={handleClosePaymentType}
                    value={PREFERRED_PAYMENT.find(
                      (option) =>
                        option.value === formik.values.preferredPaymentType
                    )}
                    onChange={(selectedOption) =>
                      formik.setFieldValue(
                        "preferredPaymentType",
                        selectedOption.value
                      )
                    }
                    styles={CUSTOMDROPDOWNSTYLE}
                  />
                </Form.Group>
                {/* </Col>
                <Col lg={6}> */}
                <Form.Group className="form-group">
                  <Form.Label className="text-dark">
                    {t("profile")} :
                  </Form.Label>
                  {selectedImage == "" || selectedImage === null ? (
                    <Form.Control
                      type="file"
                      id="files"
                      name="profile"
                      accept="image/*"
                      onChange={(e) => {
                        handlefileChange(e);
                      }}
                    />
                  ) : (
                    <div
                      style={{ width: "auto" }}
                      className="d-flex justify-content-center"
                    >
                      <input
                        type="file"
                        id="file"
                        name="profile"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handlefileChange(e);
                        }}
                      />
                      <label
                        style={{
                          cursor: "pointer",
                          bottom: "95px",
                        }}
                        className="btn btn-warning btn-sm position-absolute z-index-10 dealer-model-file"
                        htmlFor="file"
                      >
                        <i class="fa fa-image"></i> Change Profile
                      </label>
                      <img
                        className="position-relative"
                        src={selectedImage}
                        alt="upload"
                        style={{
                          objectFit: "cover",
                          width: "400px",
                          height: "300px",
                        }}
                      />
                    </div>
                  )}
                </Form.Group>
                {/* </Col> */}
              </>
            )}
            <Form.Group>
              <Button
                type="submit"
                className="btn ripple btn-primary btn-block mt-3"
              >
                {update ? t("update") : t("add")}
              </Button>
            </Form.Group>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddUser;
