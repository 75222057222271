import { useTranslation } from "react-i18next";
import ServerCall from "../../serverCall";

const UserService = () => {
  const serverCall = ServerCall();
  const { t } = useTranslation("common");
  const headers = {
    "Accept-Language": t("language"),
  };

  const getAllUsers = async (
    fromDate,
    pageLimit,
    pageNumber,
    parentId,
    role,
    searchText,
    toDate,
    verified
  ) => {
    const body = {
      fromDate,
      pageLimit,
      pageNumber,
      parentId,
      role,
      searchText,
      toDate,
      verified,
    };
    const response = await serverCall.customAxios.post(`/user/all`, body, {
      headers,
    });
    return response;
  };

  const getAllChildBusinessPartners = async (
    pageLimit,
    pageNumber,
    parentId,
    role,
    searchText
  ) => {
    const requestBody = {
      pageLimit,
      pageNumber,
      parentId,
      role,
      searchText,
    };
    const response = await serverCall.customAxios.post(
      `/user/childbusinesspartner/all`,
      requestBody,
      { headers }
    );
    return response;
  };

  const addUser = async (body) => {
    const response = await serverCall.customAxios.post(`/user/`, body, {
      headers,
    });
    return response;
  };

  const getAdmin = async () => {
    const response = await serverCall.customAxios.get(`/user/admin`, {
      headers,
    });
    return response;
  };

  const getSyncedWalletAmount = async (id) => {
    const response = await serverCall.customAxios.get(
      `/user/walletamount/${id}`,
      {
        headers,
      }
    );
    return response;
  };

  const updateUser = async (id, body) => {
    const response = await serverCall.customAxios.put(
      `/user/${id}/admin`,
      body,
      {
        headers,
      }
    );
    return response;
  };

  const getChildBusinessPartnersById = async (id) => {
    const response = await serverCall.customAxios.get(
      `/user/controlleraccount/${id}`,
      {
        headers,
      }
    );
    return response;
  };

  const getUserById = async (id) => {
    const response = await serverCall.customAxios.get(`/user/${id}`, {
      headers,
    });
    return response;
  };

  const deleteUser = async (id) => {
    const response = await serverCall.customAxios.delete(`/user/${id}`, {
      headers,
    });
    return response;
  };

  const getRoleUsers = async () => {
    const response = await serverCall.customAxios.get(
      `/user/?roles=USER&roles=BUSINESS_USER`,
      {
        headers,
      }
    );
    return response;
  };

  const addProfile = (profile) => {
    const response = serverCall.customAxios.post(`/user/profile`, profile, {
      headers,
    });
    return response;
  };

  const deleteUnverifiedUser = (fromDate, toDate) => {
    const response = serverCall.customAxios.delete(
      `/user/unverified/?fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers,
      }
    );
    return response;
  };

  return {
    getAllUsers,
    addUser,
    updateUser,
    getUserById,
    getAllChildBusinessPartners,
    getChildBusinessPartnersById,
    deleteUser,
    getRoleUsers,
    addProfile,
    getAdmin,
    deleteUnverifiedUser,
    getSyncedWalletAmount,
  };
};

export default UserService;
