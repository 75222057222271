import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Fragment } from "react";
import { Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate, useParams } from "react-router-dom";
import withLoader from "../../layout/Loader/WithLoader";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2pdf from "html2pdf.js";
import TransactionSlip from "./TransactionSlip.js";
import UserService from "../../services/user.service";
import { useEffect } from "react";
import moment from "moment";
import { ScrollToTop } from "../../utils/ScrollTop";
import STATE from "../../utils/city.json";
import DateRangePicker from "react-bootstrap-daterangepicker";
import NeosLogo from "../../../assets/img/neos-logo.jpg";
import {
  ANCHORORIGIN,
  CUSTOM_STYLES,
  EMPTY,
  ERROR,
  PAGELIMIT,
  ReplaceFunction,
  SUCCESS,
} from "../../utils/constants";
import ParkingHistoryService from "../../services/parkingHistory.service";
import ExcelJS from "exceljs";
import { useTranslation } from "react-i18next";
import useDialogState from "../../hooks/useDialog";
import AddUsers from "./AddUsers";
import * as yup from "yup";
import { useFormik } from "formik";
import useMenuState from "../../hooks/useMenuState";
import CityService from "../../services/city.service";
import { enqueueSnackbar } from "notistack";
import DeleteUser from "./DeleteUser";
import TransactionService from "../../services/transaction.service";
import Enums from "../../utils/enums";

const Profie = (props) => {
  const parkingHistoryService = ParkingHistoryService();
  const cityService = CityService();
  const { RANGES } = Enums();
  const transactionService = TransactionService();
  const userService = UserService();
  const { t } = useTranslation("common");
  const { id } = useParams();
  const navigate = useNavigate();
  const [dataPerPage, setDataPerPage] = useState(PAGELIMIT);
  const [historyDataPerPage, setHistoryDataPerPage] = useState(PAGELIMIT);

  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [historyStartDate, setHistoryStartDate] = useState(0);
  const [historyEndDate, setHistoryEndDate] = useState(0);
  const [options, setOptions] = useState("");
  const [options2, setOptions2] = useState("");
  const [role, setRole] = useState("");
  const [parkingData, setParkingData] = useState([]);
  const [parkingTotalRows, setParkingTotalRows] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [historyPageNumber, setHistoryPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState("");
  const [cashbackInAmount, setCashbackInAmount] = useState(true);
  const [userData, setUserData] = useState("");
  const [childData, setChildData] = useState([]);
  const [update, setUpdate] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [newTown, setNewTown] = useState("");
  const [parentData, setParentData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [userNames, setUserNames] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [newCity, setNewCity] = useState([]);
  const [newRole, setNewRole] = useState("");
  const [newPaymentType, setNewPaymentType] = useState("");
  const [showReason, setShowReason] = useState(false);
  const [oldWalletAmount, setOldWalletAmount] = useState("");
  const [newType, setNewType] = useState("");
  const [newBusinessPartner, setNewBusinessPartner] = useState("");
  const [searchString, setSearchString] = useState("");
  const [historySearchString, setHistorySearchString] = useState("");
  const [details, setDetails] = useState({});
  const [changeImage, setChangeImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [vatNumber, setVatNumber] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [visibleOptions, setVisibleOptions] = useState(10);
  const [search, setSearch] = useState("");
  const [adminDetails, setAdminDetails] = useState("");
  const NewState = [
    ...new Set(STATE.map((data) => data.STATE + " (" + data.PROVINCE + ")")),
  ];
  const [stateOptions, setStateOptions] = useState(
    NewState.sort((a, b) => a.localeCompare(b)).map((state) => ({
      label: state,
      value: state.match(/\((.*?)\)/)[1],
    }))
  );
  const [cityOptions, setCityOptions] = useState(
    STATE.map((state) => ({
      label: state.CITY,
      value: state.CITY,
    }))
  );

  const {
    open: isDialogOpen,
    handleClickOpen: handleDialogOpen,
    handleClose: handleDialogClose,
  } = useDialogState();

  const [openServiceFee, handleOpenServiceFee, handleCloseServiceFee] =
    useMenuState(false);
  const [openRole, handleOpenRole, handleCloseRole] = useMenuState(false);
  const [openCity, handleOpenCity, handleCloseCity] = useMenuState(false);
  const [openBusinessUser, handleOpenBusinessUser, handleCloseBusinessUser] =
    useMenuState(false);
  const [openPaymentType, handleOpenPaymentType, handleClosePaymentType] =
    useMenuState(false);
  const [openDealerType, handleOpenDealerType, handleCloseDealerType] =
    useMenuState(false);

  const city = [
    ...newCity.map((item) => ({
      value: item.id,
      label: item.name,
    })),
  ];

  const handlefileChange = (e) => {
    setChangeImage(true);
    const selectedFile = e.currentTarget.files[0];
    formik.setFieldValue("profile", selectedFile);

    // setSelectedImage([selectedFile]);
    const imageUrl = URL.createObjectURL(selectedFile);
    setSelectedImage(imageUrl);
  };

  const handlesearch = (e) => {
    setSearchString(e.target.value);
  };
  const historyHandlesearch = (e) => {
    setHistorySearchString(e.target.value);
  };

  const handleInvoiceCode = (e) => {
    const uppercase = e.target.value.toUpperCase();
    if (uppercase.length > 7) {
      return;
    }
    formik.setFieldValue("einvoicing", uppercase);
  };

  const selectRole = (selectedOption) => {
    formik.setFieldValue("role", selectedOption.value);
    setRole(selectedOption.label);
    setOptions(selectedOption);
  };

  const getAllCity = async () => {
    const response = await cityService.getAllPageCity();
    setNewCity(response.data.apiresponse.data);
  };

  const selectPaidList = (selectedOption) => {
    formik.setFieldValue("dealerType", selectedOption.value);
    setOptions2(selectedOption);
  };

  const getAdmin = async () => {
    const adminDetailResponse = await userService.getAdmin();
    setAdminDetails(adminDetailResponse.data.apiresponse.data);
  };

  // const selectCity = async (selectedOption) => {
  //
  //   const id = selectedOption.value;
  //   formik.setFieldValue("town", selectedOption.label);
  //   setNewTown(selectedOption.label);

  //   const response = await cityService.getCity(id);
  //
  //   formik.setFieldValue("province", response.data.apiresponse.data.state);
  // };
  const selectState = (selected) => {
    // setSelectedState(selected.value);

    formik.setFieldValue(
      "province",
      { value: selected.value, label: selected.label },
      false
    );
    // setNewState(selected.label);
    const filteredCities = STATE.sort((a, b) =>
      a.label.localeCompare(b.label)
    ).filter((city) => city.PROVINCE === selected.value);
    setCityOptions(
      filteredCities.map((state) => ({
        label: state.CITY,
        value: state.CITY,
      }))
    );
  };

  const selectCity = async (selectedOption) => {
    formik.setFieldValue("town", selectedOption.label);
    setCity(selectedOption.value);
  };

  const setCity = (cityName) => {
    const filteredState = STATE.filter((city) => city.CITY === cityName);
    const state = filteredState.map((data) => ({
      label: data.STATE + " (" + data.PROVINCE + ")",
      value: data.PROVINCE,
    }));
    formik.setFieldValue("province", state.map((res) => res.value).join(", "));
  };

  cityOptions.sort((a, b) => a.label.localeCompare(b.label));

  const loadMore = () => {
    setVisibleOptions((prev) => prev + 10);
  };

  const filteredOptions = cityOptions
    .sort((a, b) => a.label.localeCompare(b.label))
    .filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase())
    );

  const selectServiceFee = (selected) => {
    formik.setFieldValue("serviceFeeApplied", selected.value);
  };

  const businessUsers = [
    ...parentData.map((item) => ({
      value: item.id,
      // label: item.userName,
      label: item.companyName,
    })),
  ];

  const setDates = (e, { startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const setHistoryDates = (e, { startDate, endDate }) => {
    setHistoryStartDate(startDate);
    setHistoryEndDate(endDate);
  };

  const cancelDate = () => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const cancelHistoryDate = () => {
    setHistoryStartDate(startDate);
    setHistoryEndDate(endDate);
  };

  const handleDateChange = () => {
    setStartDate(0);
    setEndDate(0);
  };

  const handleHistoryDateChange = () => {
    setStartDate(0);
    setEndDate(0);
  };

  const setParkingDates = (e, { startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleVAT = (event) => {
    const input = event.target.value;

    const sanitizedInput = input.replace(/[^\d+]/g, "");

    setVatNumber(sanitizedInput);
    formik.setFieldValue("vatNumber", sanitizedInput);
  };

  // format phone for API data
  const handleChangePhone = (country, value) => {
    if (country.startsWith(value.dialCode)) {
      // Remove the countryCode from the beginning of the Number
      const number = country.slice(value.dialCode.length);

      formik.setFieldValue("phoneNo", number);
      formik.setFieldValue("countryCode", value.dialCode);
    }
  };

  const selectBusinessUser = (selectedOption) => {
    formik.setFieldValue("parentUserId", selectedOption.value);
  };

  const formatDateRange = (startDate) => {
    return `${startDate}`;
  };
  const handleExpirationDate = (selectedDates) => {
    const formattedDates = formatDateRange(selectedDates);

    const dateFormat = "DD/MM/YYYY";
    const dateMilliseconds = moment(formattedDates, dateFormat).valueOf();

    formik.setFieldValue("expiration", dateMilliseconds);
  };

  // Get user details by id and set data to profile page
  const getUserById = async () => {
    const response = await userService.getUserById(id);
    setUserData(response.data.apiresponse.data);
    const result = response.data.apiresponse.data;

    // Set field value when click on the edit
    if (result.parentId > 0) {
      const businessName = await userService.getUserById(result.parentId);
      const parentUsername = businessName.data.apiresponse.data.username;

      setNewBusinessPartner(parentUsername);
    }
    setCity(result.town);
    setDetails({ name: result.name, id });
    setNewRole(result.role);
    setNewTown(result.town);
    setRole(result.role);
    setNewPaymentType(result.preferredPaymentType);
    setNewType(result.dealerType);
    setSelectedImage(result.profile);
    setVatNumber(result.vat ? result.vat : "");
    formik.setFieldValue("id", result.id);
    formik.setFieldValue("profile", result.profile);
    formik.setFieldValue("username", result.username ? result.username : "");
    formik.setFieldValue("email", result.email ? result.email : "");
    formik.setFieldValue("surname", result.surname ? result.surname : "");
    formik.setFieldValue("taxCode", result.taxCode ? result.taxCode : "");
    formik.setFieldValue("address", result.address ? result.address : "");
    formik.setFieldValue("cap", result.cap ? result.cap : "");
    formik.setFieldValue("phoneNo", result.phoneNo ? result.phoneNo : "");
    formik.setFieldValue("pec", result.pec ? result.pec : "");
    formik.setFieldValue("vatNumber", result.vat ? result.vat : "");
    formik.setFieldValue("role", result.role ? result.role : "");
    formik.setFieldValue("serviceFeeApplied", result.serviceFeeApplied);
    formik.setFieldValue("companyName", result.companyName);
    formik.setFieldValue("cashbackAmount", result.cashbackAmount);
    formik.setFieldValue("cashbackAmountInPr", result.cashbackAmountInPr);
    formik.setFieldValue(
      "monthlyFeePrice",
      result.monthlyFeePrice ? result.monthlyFeePrice : ""
    );
    formik.setFieldValue(
      "expiration",
      result.expiration ? result.expiration : ""
    );
    formik.setFieldValue(
      "childUserLimit",
      result.childLimit ? result.childLimit : ""
    );
    formik.setFieldValue(
      "childUserPassword",
      result.childUserPassword ? result.childUserPassword : ""
    );
    formik.setFieldValue("dealerType", result.dealerType);
    formik.setFieldValue(
      "countryCode",
      result.countryCode ? result.countryCode : ""
    );
    formik.setFieldValue(
      "einvoicing",
      result.einvoicing ? result.einvoicing : ""
    );
    formik.setFieldValue(
      "parentUserId",
      result.parentId ? result.parentId : ""
    );
    formik.setFieldValue(
      "percentageOfEarning",
      result.earning ? result.earning : ""
    );
    formik.setFieldValue("preferredPaymentType", result.preferredPaymentType);
    formik.setFieldValue("province", result.province ? result.province : "");
    formik.setFieldValue("region", result.region ? result.region : "");
    formik.setFieldValue("town", result.town ? result.town : "");
    formik.setFieldValue(
      "walletAmount",
      result.walletAmount ? result.walletAmount : ""
    );
    setOldWalletAmount(result.walletAmount);

    ScrollToTop();

    if (response.status === 200 || response.status === 201) {
      if (response.data.apiresponse.data.role === "CHILD_USER") {
        const parentBusinessName = await userService.getUserById(
          response.data.apiresponse.data.parentId
        );
        // set parent user name when response get
        setUserNames(parentBusinessName.data.apiresponse.data.userName);
        setCompanyName(parentBusinessName.data.apiresponse.data.companyName);
      }
    }

    if (response.status === 200 || response.status === 201) {
      if (response.data.apiresponse.data.role === "BUSINESS_USER") {
        const childUsers = await userService.getAllUsers(
          EMPTY,
          historyDataPerPage,
          historyPageNumber,
          response.data.apiresponse.data.id,
          "CHILD_USER",
          EMPTY,
          EMPTY,
          EMPTY
        );
        // get child user data when response get

        setChildData(childUsers.data.apiresponse.data.users);
        setTotalRows(childUsers.data.apiresponse.data.totalRecords);
      }
    }
  };

  const deleteUser = async () => {
    try {
      const response = await userService.deleteUser(id);
      enqueueSnackbar(t("SUCCESSFULLY_BUSINESSPARTNER_DELETED"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
      navigate("/users/");
      setModalShow(false);
    } catch (e) {
      enqueueSnackbar(e.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  const getBusinessUserName = async () => {
    const response = await userService.getAllUsers(
      EMPTY,
      EMPTY,
      EMPTY,
      EMPTY,
      "BUSINESS_USER",
      EMPTY,
      EMPTY,
      EMPTY
    );
    setParentData(response.data.apiresponse.data.users);
  };

  const getParkingHistory = async () => {
    try {
      const response = await parkingHistoryService.getParkingHistory({
        areaId: null,
        cityId: null,
        fromDate: new Date(startDate).getTime(),
        pageLimit: dataPerPage,
        pageNumber: pageNumber,
        parentId: id,
        searchString: searchString,
        toDate: new Date(endDate).getTime(),
      });

      setParkingData(response.data.apiresponse.data.bookings);

      setParkingTotalRows(response.data.apiresponse.data.totalRecords);
    } catch (error) {
      console.log(error);
    }
  };

  const getHistoryOfUserRecharges = async () => {
    try {
      const response = await transactionService.getDealerToUserRefills({
        endDate: new Date(historyEndDate).getTime(),
        needDealerList: true,
        pageLimit: historyDataPerPage,
        pageNumber: historyPageNumber,
        searchString: historySearchString,
        startDate: new Date(historyStartDate).getTime(),
        status: null,
        userId: id,
      });
      setHistoryData(response.data.apiresponse.data.recharges);
      setTotalRows(response.data.apiresponse.data.totalRecords);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const historyHandlePageChange = (newPageNumber) => {
    setHistoryPageNumber(newPageNumber);
  };

  const changeCashback = (e) => {
    const value = e.target.value;
    if (value === "$") {
      setCashbackInAmount(true);
    } else {
      setCashbackInAmount(false);
    }
  };

  useEffect(() => {
    getAdmin();
    getUserById();
    getParkingHistory();
    getAllCity();
    getBusinessUserName();
    getHistoryOfUserRecharges();
  }, []);

  useEffect(() => {
    getParkingHistory();
  }, [pageNumber, startDate, endDate, dataPerPage, searchString]);

  useEffect(() => {
    getHistoryOfUserRecharges();
  }, [
    historyStartDate,
    historyEndDate,
    historyDataPerPage,
    historyPageNumber,
    historySearchString,
  ]);

  // Download transaction slip PDF file
  const handleDownload = (row) => {
    const receiptContainer = document.createElement("div");
    receiptContainer.classList.add("receipt-container");

    const transactionSlip = <TransactionSlip rowData={row} />;
    const paymentSlip = document.createElement("div");
    ReactDOM.render(transactionSlip, paymentSlip);
    receiptContainer.appendChild(paymentSlip);

    document.body.appendChild(receiptContainer);

    const options = {
      margin: -5,
      filename: `${row.uusername}_Payment_Slip.pdf`,
      image: { type: "jpeg", quality: 1.0 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "px",
        format: [550, 595],
        orientation: "portrait",
      },
    };

    html2pdf().from(receiptContainer).set(options).save();

    document.body.removeChild(receiptContainer);
  };

  let data = [];
  let columns = [];

  const ParkColumns = [
    {
      name: (
        <strong className="tx-uppercase">
          {t("childuser") + " " + t("name")}
        </strong>
      ),
      selector: "username",
      cell: (row) => (
        <div title={`${row.username ? row.username : "--"}`}>
          {row.username ? row.username : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("numberplate")}</strong>,
      selector: "numberPlate",
      cell: (row) => (
        <div title={`${row.numberPlate ? row.numberPlate : "--"}`}>
          {row.numberPlate ? row.numberPlate : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("parkingname")}</strong>,
      selector: "parkingName",
      cell: (row) => (
        <div title={`${row.parkingName ? row.parkingName : "--"}`}>
          {row.parkingName ? row.parkingName : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("area")}</strong>,
      selector: "areaName",
      cell: (row) => (
        <div title={`${row.areaName ? row.areaName : "--"}`}>
          {row.areaName ? row.areaName : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("streetname")}</strong>,
      selector: "bookingStreetName",
      cell: (row) => (
        <div title={`${row.bookingStreetName ? row.bookingStreetName : "--"}`}>
          {row.bookingStreetName ? row.bookingStreetName : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("starttime")}</strong>,
      selector: "startDateTime",
      cell: (row) => (
        <div
          title={`${moment(row.startDateTime).format("DD/MM/YYYY HH:mm:ss")}`}
        >
          {moment(row.startDateTime).format("DD/MM/YYYY HH:mm:ss")}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("endtime")}</strong>,
      selector: "endDateTime",
      cell: (row) => (
        <div title={`${moment(row.endDateTime).format("DD/MM/YYYY HH:mm:ss")}`}>
          {moment(row.endDateTime).format("DD/MM/YYYY HH:mm:ss")}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("cost")}</strong>,
      selector: "charge",
      cell: (row) => (
        <div
          title={`${
            row.charge
              ? `€${ReplaceFunction(row.charge?.toFixed(2))}`
              : row.charge
          }`}
        >
          {row.charge
            ? `€${ReplaceFunction(row.charge?.toFixed(2))}`
            : row.charge}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("servicefeeapplied")}</strong>,
      selector: "serviceFee",
      cell: (row) => (
        <div
          title={`${
            row.serviceFee
              ? `€${ReplaceFunction(row.serviceFee?.toFixed(2))}`
              : row.serviceFee
          }`}
        >
          {row.serviceFee
            ? `€${ReplaceFunction(row.serviceFee?.toFixed(2))}`
            : row.serviceFee}
        </div>
      ),
      sortable: true,
    },
  ];

  // Put conditiion by role
  if (userData.role === "BUSINESS_USER") {
    columns = [
      {
        name: <strong className="tx-uppercase">{t("uniqueid")}</strong>,
        selector: "uniqId",
        cell: (row) => <div title={`${row.uniqId}`}>{row.uniqId}</div>,
        sortable: true,
      },
      {
        name: <strong className="tx-uppercase">{t("name")}</strong>,
        selector: "userName",
        cell: (row) => (
          <div title={`${row.userName ? row.userName : "--"}`}>
            {row.userName ? row.userName : "--"}
          </div>
        ),
        sortable: true,
      },
      {
        name: <strong className="tx-uppercase">{t("email")}</strong>,
        selector: "email",
        cell: (row) => (
          <div title={`${row.email ? row.email : "--"}`}>
            {row.email ? row.email : "--"}
          </div>
        ),
        sortable: true,
      },
      {
        name: <strong className="tx-uppercase">{t("role")}</strong>,
        selector: "role",
        cell: (row) => (
          <div title={`${row.role ? row.role : "--"}`}>
            {row.role ? row.role : "--"}
          </div>
        ),
        sortable: true,
      },
      {
        name: <strong className="tx-uppercase">{t("creationdate")}</strong>,
        selector: "creationDate",
        cell: (row) => (
          <div
            title={`${
              row.creationDate
                ? moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")
                : "--"
            }`}
          >
            {row.creationDate
              ? moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")
              : "--"}
          </div>
        ),
        sortable: true,
      },
      {
        name: <strong className="tx-uppercase">{t("deleted")}</strong>,
        selector: "deleted",
        cell: (row) => (
          <div title={`${row.deleted ? "Yes" : "No"}`}>
            {row.deleted ? (
              <div className="text-success">
                <i className="fa fa-check"></i> Yes
              </div>
            ) : (
              <div className="text-danger">
                <i className="fa fa-close"></i> No
              </div>
            )}
          </div>
        ),
        sortable: true,
      },
    ];
  } else {
    columns = [
      {
        name: <strong className="tx-uppercase">{t("uniqueid")}</strong>,
        selector: "uuniqueId",
        cell: (row) => <div title={`${row.uuniqueId}`}>{row.uuniqueId}</div>,
        sortable: true,
      },
      {
        name: <strong className="tx-uppercase">{t("username")}</strong>,
        selector: "uusername",
        cell: (row) => (
          <div title={`${row.uusername ? row.uusername : "--"}`}>
            {row.uusername ? row.uusername : "--"}
          </div>
        ),
        sortable: true,
      },
      {
        name: (
          <strong className="tx-uppercase">{t("importbeforerecharge")}</strong>
        ),
        selector: "beforeAmount",
        cell: (row) => (
          <div
            title={`${
              row.beforeAmount
                ? `€${ReplaceFunction(row.beforeAmount?.toFixed(2))}`
                : "--"
            }`}
          >
            {row.beforeAmount
              ? `€${ReplaceFunction(row.beforeAmount?.toFixed(2))}`
              : "--"}
          </div>
        ),
        sortable: true,
      },
      {
        name: <strong className="tx-uppercase">{t("importrecharge")}</strong>,
        selector: "amount",
        cell: (row) => (
          <div
            title={`${
              row.amount ? `€${ReplaceFunction(row.amount?.toFixed(2))}` : "--"
            }`}
          >
            {row.amount ? `€${ReplaceFunction(row.amount?.toFixed(2))}` : "--"}
          </div>
        ),
        sortable: true,
      },
      {
        name: (
          <strong className="tx-uppercase">{t("importafterrecharge")}</strong>
        ),
        selector: "afterAmount",
        cell: (row) => (
          <div
            title={`${
              row.afterAmount
                ? `€${ReplaceFunction(row.afterAmount?.toFixed(2))}`
                : "--"
            }`}
          >
            {row.afterafterAmount
              ? `€${ReplaceFunction(row.amount?.toFixed(2))}`
              : "--"}
          </div>
        ),
        sortable: true,
      },
      {
        name: <strong className="tx-uppercase">{t("creationdate")}</strong>,
        selector: "creationDate",
        cell: (row) => (
          <div
            title={`${
              row.creationDate
                ? moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")
                : "--"
            }`}
          >
            {row.creationDate
              ? moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")
              : "--"}
          </div>
        ),
        sortable: true,
      },
      {
        name: <strong className="tx-uppercase">{t("typeofrecharge")}</strong>,
        selector: "type",
        cell: (row) => <div title={`${row.type}`}>{row.type}</div>,
        sortable: true,
      },
      {
        name: <strong className="tx-uppercase">{t("status")}</strong>,
        selector: "status",
        cell: (row) => (
          <div>
            <div className="">
              {row.status === "PENDING" && (
                <>
                  <div className="tx-warning d-flex align-items-center">
                    <i className="fe fe-clock me-1"></i> {t("pending")}
                  </div>
                </>
              )}
            </div>
            <div className="">
              {row.status === "COMPLETED" && (
                <div className="tx-success d-flex align-items-center">
                  <i className="fa fa-check me-1"></i> {t("completed")}
                </div>
              )}
            </div>
            <div className="">
              {row.status === "CANCEL" && (
                <div className="tx-danger d-flex align-items-center">
                  <i class="si si-close me-1"></i> {t("canceled")}
                </div>
              )}
            </div>
            <div className="">
              {row.status === "DISPUTE" && (
                <div className="tx-info d-flex align-items-center">
                  <i class="fas fa-ban me-1"></i> {t("dispute")}
                </div>
              )}
            </div>
          </div>
        ),
        sortable: true,
      },
      {
        name: <strong>{t("action")}</strong>,
        cell: (row) => (
          <div className="d-flex">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => handleDownload(row)}
            >
              <i class="fas fa-file-invoice"></i>
            </button>
          </div>
        ),
        sortable: true,
      },
    ];
  }

  // put condition by role
  if (userData.role === "BUSINESS_USER") {
    data = childData;
  } else {
    data = historyData;
  }

  const initialValue = {
    address: "",
    cap: "",
    childUserPassword: "",
    countryCode: "",
    dealerType: null,
    einvoicing: "",
    email: "",
    parentUserId: "",
    percentageOfEarning: "",
    phoneNo: "",
    preferredPaymentType: null,
    province: "",
    region: "",
    role: "",
    surname: "",
    taxCode: "",
    town: "",
    cityIds: [],
    username: "",
    vatNumber: "",
    walletAmount: null,
    pec: "",
    childUserLimit: null,
    serviceFeeApplied: null,
    monthlyFeePrice: null,
    expiration: null,
    profile: null,
    companyName: "",
    cashbackAmount: 0,
    cashbackAmountInPr: 0,
  };

  // validation for form
  const validationSchema = yup.object().shape({
    username:
      options.value === "DEALER" ||
      newRole === "DEALER" ||
      options.value === "BUSINESS_USER" ||
      newRole === "BUSINESS_USER"
        ? yup.string()
        : yup.string().required("Please Enter User Name!"),
    surname:
      options.value === "DEALER" ||
      newRole === "DEALER" ||
      options.value === "BUSINESS_USER" ||
      newRole === "BUSINESS_USER"
        ? yup.string()
        : yup.string().required("Please Enter Surname"),
    email: yup.string().email().required("Please Enter Email"),
    role: yup.string().required("Please Enter Role!"),
    walletAmountReason: showReason
      ? yup.string().required("Please Enter Reason for Change Wallet Amount")
      : yup.string(),

    // Business User
    taxCode:
      options.value === "USER" || newRole === "USER"
        ? yup
            .string()
            .matches(
              /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/,
              "Invalid tax code Example : RRSMRA74D22A001Q"
            )
            .max(16, "Maximun 16 characters allowed")
            .required("Tax code is Required!")
        : yup
            .string()
            .matches(
              /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/,
              "Invalid tax code Example : RRSMRA74D22A001Q"
            )
            .max(16, "Maximun 16 characters allowed"),
    vatNumber:
      options.value === "BUSINESS_USER" ||
      newRole === "BUSINESS_USER" ||
      options.value === "DEALER" ||
      newRole === "DEALER"
        ? yup
            .string()
            .matches(/^\d{11}$/, "Please enter exactly 11 digits")
            .required("Please Enter VAT")
        : yup.string().matches(/^\d{11}$/, "Please enter exactly 11 digits"),

    address:
      options.value === "BUSINESS_USER" ||
      options.value === "USER" ||
      options.value === "DEALER" ||
      newRole === "BUSINESS_USER" ||
      newRole === "USER" ||
      newRole === "DEALER"
        ? yup.string().required("PLease Enter Street Address")
        : yup.string(),
    town:
      options.value === "BUSINESS_USER" ||
      options.value === "USER" ||
      options.value === "DEALER" ||
      newRole === "BUSINESS_USER" ||
      newRole === "USER" ||
      newRole === "DEALER"
        ? yup.mixed().required("Please Enter City")
        : yup.mixed(),
    province:
      options.value === "BUSINESS_USER" ||
      options.value === "USER" ||
      options.value === "DEALER" ||
      newRole === "BUSINESS_USER" ||
      newRole === "USER" ||
      newRole === "DEALER"
        ? yup.mixed().required("Please Enter State")
        : yup.mixed(),
    cap:
      options.value === "BUSINESS_USER" ||
      options.value === "USER" ||
      options.value === "DEALER" ||
      newRole === "BUSINESS_USER" ||
      newRole === "USER" ||
      newRole === "DEALER"
        ? yup.string().max(5, "Maximum 5 numbers!").min(5, "Minimum 5 numbers!")
        : yup.mixed(),

    // Child user
    childUserPassword:
      options.value === "CHILD_USER" || (newRole === "CHILD_USER" && !update)
        ? yup.string().required("Please Enter childUserPassword")
        : yup.string(),
    parentUserId:
      options.value === "CHILD_USER" || newRole === "CHILD_USER"
        ? yup.string().required("Please Enter Business User")
        : yup.string(),

    einvoicing: yup
      .string()
      .matches(/^[A-Z0-9]+$/, "It contains only UPPERCASE letters and Numbers")
      .max(7, "Must be at most 7 characters"),
  });

  const handleSubmitForm = async (values, action) => {
    const {
      id,
      username,
      email,
      childUserPassword,
      province,
      role,
      parentUserId,
      address,
      cap,
      countryCode,
      dealerType,
      einvoicing,
      percentageOfEarning,
      phoneNo,
      preferredPaymentType,
      region,
      surname,
      taxCode,
      town,
      cityIds,
      vatNumber,
      walletAmount,
      pec,
      childUserLimit,
      serviceFeeApplied,
      monthlyFeePrice,
      expiration,
      profile,
      companyName,
      cashbackAmount,
      cashbackAmountInPr,
    } = values;
    try {
      const regex = /\(([^)]+)\)/;
      const setProvince = province.match(regex);
      const response = await userService.updateUser(id, {
        username,
        email,
        cityIds,
        town,
        role,
        parentUserId,
        childUserPassword,
        address,
        cap,
        countryCode,
        dealerType,
        einvoicing,
        percentageOfEarning,
        phoneNo,
        preferredPaymentType,
        region,
        surname,
        taxCode,
        province,
        vatNumber,
        walletAmount,
        pec,
        childUserLimit,
        serviceFeeApplied,
        monthlyFeePrice,
        expiration,
        companyName,
        cashbackAmount,
        cashbackAmountInPr,
      });

      const editdata = new FormData();
      editdata.append("profile", profile);
      editdata.append("userId", id);

      if (changeImage) {
        const profileImage = await userService.addProfile(editdata);
      }

      setChangeImage(false);
      getUserById();
      // setRole("");
      // setNewRole("");
      // handleDialogClose();
      // action.resetForm();
      enqueueSnackbar(t("SUCCESSFULLY_USER_UPDATED"), {
        variant: SUCCESS,
        anchorOrigin: ANCHORORIGIN,
      });
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  const getSyncedWalletAmount = async () => {
    try {
      const response = await userService.getSyncedWalletAmount(id);
      const newObject = {
        ...userData,
        syncedWalletAmount: response.data.apiresponse.data.walletAmount,
      };
      setUserData(newObject);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userData.role === "BUSINESS_USER" || userData.role === "USER") {
      getSyncedWalletAmount();
    }
  }, [id, userData.role]);

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: handleSubmitForm,
  });

  const AddModal = () => {
    setUpdate(true);
    getUserById();
    handleDialogOpen();
  };

  const handleCAPChange = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^\d+]/g, "");

    formik.setFieldValue("cap", sanitizedInput);
  };

  const handlePercentage = (e) => {
    const value = e.target.value;
    const sanitizedInput = value.replace(/[^\d+]/g, "");
    if (sanitizedInput.length > 4) {
      return;
    }

    let formattedNumber = "";
    for (let i = 0; i < sanitizedInput.length; i++) {
      if (i === 2) {
        formattedNumber += `.${sanitizedInput.charAt(i)}`;
      } else {
        formattedNumber += sanitizedInput.charAt(i);
      }
    }
    formik.setFieldValue("percentageOfEarning", formattedNumber);
  };

  let totalCost = 0;
  for (let i = 0; i < data?.length; i++) {
    totalCost += parseFloat(data[i]?.amount);
  }

  // Export PDF function logic
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const pageWidth = 595.28;
    const titleFontSize = 18;
    const width = 515;
    const textColor = [0, 0, 0];
    const fillColor = [200, 200, 200];

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(titleFontSize);

    const title = "Ricariche NeosApp";
    const titleWidth = doc.getStringUnitWidth(title) * titleFontSize;
    const titleX = (pageWidth - titleWidth) / 2;
    doc.setFillColor(...fillColor);
    doc.setDrawColor(...textColor);
    doc.rect(40, 16, width, 26, "FD");
    doc.setTextColor(...textColor);
    doc.setFont("helvetica", "bold");
    doc.text(title, titleX, 35);

    const catania = userData.town;
    doc.setFillColor(...fillColor);
    doc.setDrawColor(...textColor);
    doc.rect(40, 50, width, 26, "FD");
    doc.setTextColor(...textColor);
    doc.text(catania, 45, 70);

    const punto = "Punto ricarica: ";
    const name = `${userData.town} - ${userData.address}`;
    const salvatore = `${userData.username} ${userData.surname}`;
    doc.setFillColor(...fillColor);
    doc.setDrawColor(...textColor);
    doc.rect(40, 80, width, 54, "FD");
    doc.setTextColor(...textColor);
    doc.text(punto, 45, 100);
    doc.text(name, 200, 100);
    doc.text(salvatore, 200, 125);

    const period = "Periodo dal";
    const startTime = startDate ? moment(startDate).format("DD/MM/YYYY") : "--";
    const endTime = `al  ${
      endDate ? moment(endDate).format("DD/MM/YYYY") : "--"
    }`;
    doc.setFillColor(...fillColor);
    doc.setDrawColor(...textColor);
    doc.rect(40, 134, width, 26, "FD");
    doc.setTextColor(...textColor);
    doc.text(period, 45, 152);
    doc.text(startTime, 220, 152);
    doc.text(endTime, 330, 152);

    const headers = [["Unique ID", "Card", "Data", "Descrizione", "Ricariche"]];
    // set data table columns names
    const newData = data.map((elt) => [
      elt.transactionId,
      elt.uuniqueId,
      elt.creationDate
        ? moment(elt.creationDate).format("DD/MM/YYYY HH:mm:ss")
        : "--",
      elt.reasonForCancel ? elt.reasonForCancel : "--",
      "€ " + elt.amount,
    ]);

    newData.push([
      "",
      "",
      {
        content: "Total ricariche:",
        colSpan: 2,
        styles: {
          halign: "right",
          fontStyle: "bold",
          fillColor: [...fillColor],
          textColor: [...textColor],
          lineWidth: 0.5,
          lineColor: [...textColor],
        },
      },
      {
        content: "€ " + totalCost,
        colSpan: 1,
        styles: {
          halign: "right",
          textColor: [...textColor],
          lineWidth: 0.5,
          lineColor: [...textColor],
        },
      },
    ]);

    const headStyles = {
      fillColor: [...fillColor],
      textColor: [...textColor],
      lineWidth: 0.5,
      lineColor: [...textColor],
      halign: "center",
      fontSize: 13,
    };

    const bodyStyles = {
      fillColor: "#ffffff",
      halign: "center",
      textColor: [...textColor],
      cellPadding: { left: 5, right: 5, top: 7, bottom: 7 },
      fontSize: 13,
    };

    const columnStyles = {
      4: { halign: "right" },
    };

    const alternateRowStyles = {
      fillColor: "#ffffff",
      textColor: [...textColor],
    };

    if (typeof doc.autoTable === "function") {
      doc.autoTable({
        startY: 180,
        head: headers,
        body: newData,
        headStyles: headStyles,
        bodyStyles: bodyStyles,
        alternateRowStyles: alternateRowStyles,
        columnStyles: columnStyles,
      });
    } else {
      console.error("jspdf-autotable plugin not loaded properly.");
    }

    doc.save(`${userData.username}_User_Recharges.pdf`);
  };

  const handleDownloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    const commonCellStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCCCCC" },
      },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
      font: { color: { argb: "000000" }, bold: true },
    };

    const titleRow = worksheet.addRow(["Ricariche NeosApp"]);
    worksheet.mergeCells("A1:E1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    titleRow.getCell(1).fill = commonCellStyle.fill;
    titleRow.getCell(1).border = commonCellStyle.border;
    titleRow.getCell(1).font = commonCellStyle.font;
    worksheet.addRow([]);
    worksheet.mergeCells("A2:E2");

    const catania = worksheet.addRow([userData.town]);
    worksheet.mergeCells("A3:E3");
    catania.getCell(1).fill = commonCellStyle.fill;
    catania.getCell(1).border = commonCellStyle.border;
    catania.getCell(1).font = commonCellStyle.font;

    const punto = worksheet.addRow([
      `Punto ricarica:   ${userData.town} - ${userData.address}`,
    ]);
    worksheet.mergeCells("A4:E4");
    punto.getCell(1).fill = commonCellStyle.fill;
    punto.getCell(1).border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "none" },
      right: { style: "thin", color: { argb: "000000" } },
    };
    punto.getCell(1).font = commonCellStyle.font;

    const salvatore = worksheet.addRow([
      `                                ${userData.username} ${userData.surname}`,
    ]);
    worksheet.mergeCells("A5:E5");
    salvatore.getCell(1).fill = commonCellStyle.fill;
    salvatore.getCell(1).border = {
      top: { style: "none" },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
    salvatore.getCell(1).font = commonCellStyle.font;

    const period = worksheet.addRow([
      `Periodo dal               ${
        startDate ? moment(startDate).format("DD/MM/YYYY HH:mm:ss") : "--"
      } al  ${endDate ? moment(endDate).format("DD/MM/YYYY HH:mm:ss") : "--"}`,
    ]);
    worksheet.mergeCells("A6:E6");
    period.getCell(1).fill = commonCellStyle.fill;
    period.getCell(1).border = commonCellStyle.border;
    period.getCell(1).font = commonCellStyle.font;

    worksheet.addRow([]);
    worksheet.mergeCells("A7:E7");

    // Add column headers
    const headers = ["Unique ID", "Card", "Data", "Descrizione", "Ricariche"];
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 18;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 10;
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.fill = commonCellStyle.fill;
      cell.font = commonCellStyle.font; // Text color
      cell.border = commonCellStyle.border;
    });

    // Add data rows
    data.forEach((res) => {
      const table = worksheet.addRow([
        res.transactionId,
        res.uuniqueId,
        res.creationDate
          ? moment(res.creationDate).format("DD/MM/YYYY HH:mm:ss")
          : "--",
        res.reasonForCancel ? res.reasonForCancel : "--",
        `€${res.amount}`,
      ]);
      table.eachCell((cell) => {
        cell.border = {
          top: { style: "none" },
          left: { style: "none" },
          bottom: { style: "none" },
          right: { style: "none" },
        };
      });
    });

    const totalRicaricheRow = worksheet.addRow([
      "",
      "",
      "",
      "Total ricariche:",
      `€${totalCost}`,
    ]);
    totalRicaricheRow.getCell(4).fill = commonCellStyle.fill;
    totalRicaricheRow.getCell(4).border = commonCellStyle.border;
    totalRicaricheRow.getCell(5).border = commonCellStyle.border;

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Download the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${userData.username}_User_Recharges.xlsx`;
    link.click();
  };

  let totalParkhistoryCost = 0;
  for (let i = 0; i < parkingData.length; i++) {
    totalParkhistoryCost += parseFloat(parkingData[i].charge);
  }

  // Park History Export
  const exportParkHistoryPDF = () => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3, or A4
    const orientation = "landscape"; // portrait or landscape

    const pageWidth = 1190.7; // A4 page width in points
    const titleFontSize = 15;

    const doc = new jsPDF(orientation, unit, size);

    const imgData = NeosLogo;
    doc.addImage(imgData, "JPEG", 950, 10, 170, 70);

    const title = "STORICO PARCHEGGI CREDITO REAMININD";
    const titleWidth = doc.getStringUnitWidth(title) * titleFontSize; // Calculate title width in points

    // Calculate the x-coordinate to center the title on the page
    const titleX = (pageWidth - titleWidth) / 2;
    doc.setFontSize(titleFontSize);
    doc.text(title, 40, 50);

    const footer = `NEOS Tech S.r.l. - ${adminDetails.address} - ${adminDetails.cap} (${adminDetails.province}) Tel. ${adminDetails.phoneNo} - Fax ${adminDetails.fax}`;
    doc.setFontSize(10);
    doc.text(footer, 40, 800);

    const headers = [
      [
        "CODICE UNICO",
        "CITTÀ",
        "LA ZONA",
        "DATA D'INIZIO",
        "DATA DI FINE",
        "COSTO",
        "QUOTA DI SERVIZIO PAGATA",
        "CREDITO REAMININD",
        "CREDITO PRECEDENTE",
        "TARGA",
      ],
    ];

    const tableData = parkingData.map((elt) => [
      elt.uniqId,
      elt.cityName,
      elt.areaName,
      moment(elt.startDateTime).format("DD/MM/YYYY HH:mm:ss"),
      moment(elt.endDateTime).format("DD/MM/YYYY HH:mm:ss"),
      `€${elt.charge ? ReplaceFunction(elt.charge.toFixed(2)) : elt.charge}`,
      `€${
        elt.serviceFee
          ? ReplaceFunction(elt.serviceFee.toFixed(2))
          : elt.serviceFee
      }`,
      `€${
        elt.afterCredit
          ? ReplaceFunction(elt.afterCredit.toFixed(2))
          : elt.afterCredit
      }`,
      `€${
        elt.beforeCredit
          ? ReplaceFunction(elt.beforeCredit.toFixed(2))
          : elt.beforeCredit
      }`,
      elt.numberPlate,
    ]);

    tableData.push([
      {
        content: "Parcheggio totale:",
        colSpan: 2,
        styles: { halign: "right", fontStyle: "bold" },
      },
      parkingData.length,
      {
        content: "Costo totale:",
        colSpan: 2,
        styles: { halign: "right", fontStyle: "bold" },
      },
      `€${
        totalParkhistoryCost
          ? ReplaceFunction(totalParkhistoryCost?.toFixed(2))
          : totalParkhistoryCost
      }`,
    ]);

    if (typeof doc.autoTable === "function") {
      doc.autoTable({
        startY: 100,
        head: headers,
        body: tableData,
      });
    } else {
      console.error("jspdf-autotable plugin not loaded properly.");
    }

    doc.save("Park_History.pdf");
  };

  const handleParkHistoryDownloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    const commonCellStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCCCCC" },
      },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
      alignment: { horizontal: "center" },
      font: { color: { argb: "000000" }, bold: true },
    };

    const titleRow = worksheet.addRow(["STORICO PARCHEGGI CREDITO REAMININD"]);
    worksheet.mergeCells("A1:J1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    titleRow.getCell(1).font = commonCellStyle.font;
    worksheet.addRow([]);
    worksheet.mergeCells("A2:J2");

    worksheet.addRow([]);
    worksheet.mergeCells("A3:J3");

    worksheet.addRow([]);
    worksheet.mergeCells("A4:J4");

    // Add column headers
    const headers = [
      "CODICE UNICO",
      "CITTÀ",
      "LA ZONA",
      "DATA D'INIZIO",
      "DATA DI FINE",
      "COSTO",
      "QUOTA DI SERVIZIO PAGATA",
      "CREDITO REAMININD",
      "CREDITO PRECEDENTE",
      "TARGA",
    ];
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 18;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 30;
    worksheet.getColumn(8).width = 22;
    worksheet.getColumn(9).width = 22;
    worksheet.getColumn(10).width = 15;
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.font = commonCellStyle.font;
      cell.alignment = commonCellStyle.alignment;
    });

    // Add data rows
    parkingData.forEach((res) => {
      const table = worksheet.addRow([
        res.uniqId,
        res.cityName,
        res.areaName,
        moment(res.startDateTime).format("DD/MM/YYYY HH:mm:ss"),
        moment(res.endDateTime).format("DD/MM/YYYY HH:mm:ss"),
        `€${res.charge ? ReplaceFunction(res.charge.toFixed(2)) : res.charge}`,
        `€${
          res.serviceFee
            ? ReplaceFunction(res.serviceFee.toFixed(2))
            : res.serviceFee
        }`,
        `€${
          res.afterCredit
            ? ReplaceFunction(res.afterCredit.toFixed(2))
            : res.afterCredit
        }`,
        `€${
          res.beforeCredit
            ? ReplaceFunction(res.beforeCredit.toFixed(2))
            : res.beforeCredit
        }`,
        res.numberPlate,
      ]);
      table.eachCell((cell) => {
        cell.border = {
          top: { style: "none" },
          left: { style: "none" },
          bottom: { style: "none" },
          right: { style: "none" },
        };
        cell.alignment = commonCellStyle.alignment;
      });
    });

    worksheet.addRow([]);

    const totalRicaricheRow = worksheet.addRow([
      "",
      "Parcheggio Totale:",
      `${parkingData.length}`,
      "",
      "Costo Totale:",
      `€${
        totalParkhistoryCost
          ? ReplaceFunction(totalParkhistoryCost?.toFixed(2))
          : totalParkhistoryCost
      }`,
    ]);
    totalRicaricheRow.getCell(2).alignment = commonCellStyle.alignment;
    totalRicaricheRow.getCell(3).alignment = commonCellStyle.alignment;
    totalRicaricheRow.getCell(5).alignment = commonCellStyle.alignment;
    totalRicaricheRow.getCell(6).alignment = commonCellStyle.alignment;
    totalRicaricheRow.getCell(2).font = commonCellStyle.font;
    totalRicaricheRow.getCell(3).font = commonCellStyle.font;
    totalRicaricheRow.getCell(5).font = commonCellStyle.font;
    totalRicaricheRow.getCell(6).font = commonCellStyle.font;

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Download the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Park_History.xlsx`;
    link.click();
  };

  const handleWalletAmountChange = (e) => {
    if (update) {
      setShowReason(true);
    } else {
      setShowReason(false);
    }
    const value = e.target.value;

    if (oldWalletAmount === Number(value)) {
      setShowReason(false);
    }
    formik.setFieldValue("walletAmount", value);
  };

  return (
    <Fragment>
      <div className="page-header">
        {isDialogOpen && (
          <AddUsers
            show={isDialogOpen}
            onHide={handleDialogClose}
            formik={formik}
            isDialogOpen={isDialogOpen}
            city={city}
            selectRole={selectRole}
            selectCity={selectCity}
            selectPaidList={selectPaidList}
            options={options}
            options2={options2}
            update={update}
            handleVAT={handleVAT}
            vatNumber={vatNumber}
            businessUsers={businessUsers}
            selectBusinessUser={selectBusinessUser}
            newRole={newRole}
            handleChangePhone={handleChangePhone}
            newTown={newTown}
            role={role}
            newPaymentType={newPaymentType}
            newType={newType}
            newBusinessPartner={newBusinessPartner}
            handleCloseCity={handleCloseCity}
            handleOpenCity={handleOpenCity}
            openCity={openCity}
            handleCloseRole={handleCloseRole}
            handleOpenRole={handleOpenRole}
            openRole={openRole}
            handleCloseBusinessUser={handleCloseBusinessUser}
            handleOpenBusinessUser={handleOpenBusinessUser}
            openBusinessUser={openBusinessUser}
            handleClosePaymentType={handleClosePaymentType}
            handleOpenPaymentType={handleOpenPaymentType}
            openPaymentType={openPaymentType}
            handleCloseDealerType={handleCloseDealerType}
            handleOpenDealerType={handleOpenDealerType}
            openDealerType={openDealerType}
            selectServiceFee={selectServiceFee}
            handleCloseServiceFee={handleCloseServiceFee}
            handleOpenServiceFee={handleOpenServiceFee}
            openServiceFee={openServiceFee}
            handleExpirationDate={handleExpirationDate}
            handleInvoiceCode={handleInvoiceCode}
            handlePercentage={handlePercentage}
            selectedImage={selectedImage}
            handlefileChange={handlefileChange}
            cityOptions={cityOptions}
            selectState={selectState}
            inputValue={inputValue}
            filteredOptions={filteredOptions}
            setSearch={setSearch}
            loadMore={loadMore}
            visibleOptions={visibleOptions}
            stateOptions={stateOptions}
            handleCAPChange={handleCAPChange}
            changeCashback={changeCashback}
            cashbackInAmount={cashbackInAmount}
            handleWalletAmountChange={handleWalletAmountChange}
            showReason={showReason}
          />
        )}
        {modalShow && (
          <DeleteUser
            show={modalShow}
            onHide={() => setModalShow(false)}
            details={details}
            deleteUser={deleteUser}
          />
        )}
        <h2 className="main-content-title tx-28 mt-2">
          {/* Set names by their roles */}
          {userData.role === "CHILD_USER" && t("childuser")}
          {userData.role === "BUSINESS_USER" && t("businessuser")}
          {userData.role === "DEALER" &&
            userData.dealerType === "PAID" &&
            t("paiddealer")}
          {userData.role === "DEALER" &&
            userData.dealerType === "FREE" &&
            t("freedealer")}
          {userData.role === "USER" && t("users")}
        </h2>
        <Row className="row row-sm">
          <Col sm={12} lg={12} xl={12}>
            <Card className="custom-card">
              <Card.Body>
                <Row>
                  <Col
                    sm={2}
                    lg={2}
                    xl={2}
                    className="d-flex align-items-center text-center"
                  >
                    <div>
                      {userData.role === "DEALER" ? (
                        <div className="mb-2">
                          {userData.profile ? (
                            <img
                              src={userData.profile}
                              alt=""
                              width={"300px"}
                              className="mb-2"
                            />
                          ) : (
                            <div className="text-danger">
                              Oops! Image Not Found
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      {userData.role === "DEALER" ? (
                        <div className="text-dark mb-2 mt-2">
                          <h5 className="mt-2 mb-0 font-weight-semibold">
                            {userData.username ? userData.username : "--"}
                          </h5>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                  {userData.role === "DEALER" ? (
                    <Col sm={10} lg={10} xl={10}>
                      <div className="border-bottom mb-3">
                        <FormGroup className="form-group">
                          <Row className=" row-sm">
                            <div className="ms-auto d-flex justify-content-end">
                              <Link
                                className="btn btn-sm btn-primary me-2"
                                to={`/dealerrequests`}
                                state={{ dealername: userData.username }}
                              >
                                <i className="ti-wallet"></i>{" "}
                                {t("viewrechargerequests")}
                              </Link>

                              {/* <button
                                className="btn btn-sm btn-primary"
                                onClick={() => navigate("/invoices")}
                              > */}
                              <Link
                                className="btn btn-sm btn-primary me-2"
                                to={`/invoices`}
                                state={{ dealername: userData.username }}
                              >
                                <i className="si si-docs"></i> {t("invoices")}
                              </Link>
                              {/* </button> */}

                              <button
                                className="btn btn-sm btn-primary ms-2"
                                type="button"
                                onClick={AddModal}
                              >
                                <i className="fe fe-edit"></i> {t("edit")}
                              </button>

                              <button
                                className="btn btn-sm btn-danger ms-2"
                                onClick={() => setModalShow(true)}
                              >
                                <i className="fe fe-trash"></i> {t("delete")}
                              </button>
                            </div>
                          </Row>
                        </FormGroup>
                      </div>
                      <div>
                        <div className="row">
                          <FormGroup className="form-group">
                            <Row className=" row-sm">
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("uniqueid")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.uniqId ? userData.uniqId : "--"
                                  }
                                  disabled
                                />
                              </Col>
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("name")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.username ? userData.username : "--"
                                  }
                                  disabled
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="form-group">
                            <Row className=" row-sm">
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("surname")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.surname ? userData.surname : "--"
                                  }
                                  disabled
                                />
                              </Col>
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("email")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={userData.email ? userData.email : "--"}
                                  disabled
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          {/* Set fields by their roles */}
                          {/* {(userData.role === "BUSINESS_USER" ||
                            userData.role === "DEALER" ||
                            userData.role === "USER") && (
                            <> */}
                          <FormGroup className="form-group">
                            <Row className=" row-sm">
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("mobilenumber")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.phoneNo
                                      ? "+" +
                                        userData.countryCode +
                                        " " +
                                        userData.phoneNo
                                      : "--"
                                  }
                                  disabled
                                />
                              </Col>
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("city")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={userData.town ? userData.town : "--"}
                                  disabled
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="form-group">
                            <Row className=" row-sm">
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("province")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.province ? userData.province : "--"
                                  }
                                  disabled
                                />
                              </Col>
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("taxcode")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.taxCode ? userData.taxCode : "--"
                                  }
                                  disabled
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="form-group">
                            <Row className=" row-sm">
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("vatnumber")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={userData.vat ? userData.vat : "--"}
                                  disabled
                                />
                              </Col>
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("streetaddress")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.address ? userData.address : "--"
                                  }
                                  disabled
                                />
                              </Col>
                            </Row>
                          </FormGroup>{" "}
                          <FormGroup className="form-group">
                            <Row className=" row-sm">
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("pincode")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={userData.cap ? userData.cap : "--"}
                                  disabled
                                />
                              </Col>
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("eleinvoicecode")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.einvoicing
                                      ? userData.einvoicing
                                      : "--"
                                  }
                                  disabled
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="form-group">
                            <Row className=" row-sm">
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("preferredpaymenttype")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.preferredPaymentType
                                      ? userData.preferredPaymentType
                                      : "--"
                                  }
                                  disabled
                                />
                              </Col>
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("rechargelimit")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.rechargeLimit
                                      ? `€${ReplaceFunction(
                                          userData.rechargeLimit
                                        )}`
                                      : userData.rechargeLimit
                                  }
                                  disabled
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="form-group">
                            <Row className=" row-sm">
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("percentageofearnings")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.earning
                                      ? `€${ReplaceFunction(userData.earning)}`
                                      : userData.earning
                                  }
                                  disabled
                                />
                              </Col>
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("dealer") + " " + t("type")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.dealerType
                                      ? userData.dealerType
                                      : "--"
                                  }
                                  disabled
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="form-group">
                            <Row className=" row-sm">
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("companyname")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.companyName
                                      ? userData.companyName
                                      : "--"
                                  }
                                  disabled
                                />
                              </Col>
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("deleted")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={userData.deleted ? "Yes" : "No"}
                                  disabled
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          {/* </>
                          )} */}
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <div>
                      <div className="border-bottom mb-3">
                        <FormGroup className="form-group">
                          <Row className=" row-sm">
                            <div className="ms-auto d-flex justify-content-end">
                              {/* {userData.role === "DEALER" && (
                                <button
                                  className="btn btn-sm btn-primary me-2"
                                  onClick={() => navigate("/dealerrequests")}
                                >
                                  <i className="ti-wallet"></i>{" "}
                                  {t("viewrechargerequests")}
                                </button>
                              )} */}
                              <Link
                                className="btn btn-sm btn-primary me-2"
                                to={`/invoices`}
                                state={{ dealername: userData.username }}
                              >
                                <i className="si si-docs"></i> {t("invoices")}
                              </Link>
                              {/* <button
                                className="btn btn-sm btn-primary"
                                onClick={() => navigate("/invoices")}
                              >
                                <i className="si si-docs"></i> {t("invoices")}
                              </button> */}

                              <button
                                className="btn btn-sm btn-primary ms-2"
                                type="button"
                                onClick={AddModal}
                              >
                                <i className="fe fe-edit"></i> {t("edit")}
                              </button>

                              <button
                                className="btn btn-sm btn-danger ms-2"
                                onClick={() => setModalShow(true)}
                              >
                                <i className="fe fe-trash"></i> {t("delete")}
                              </button>
                            </div>
                          </Row>
                        </FormGroup>
                      </div>
                      <div>
                        <div className="row">
                          <FormGroup className="form-group">
                            <Row className=" row-sm">
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("uniqueid")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.uniqId ? userData.uniqId : "--"
                                  }
                                  disabled
                                />
                              </Col>
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("name")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.username ? userData.username : "--"
                                  }
                                  disabled
                                />
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup className="form-group">
                            <Row className=" row-sm">
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("surname")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={
                                    userData.surname ? userData.surname : "--"
                                  }
                                  disabled
                                />
                              </Col>
                              <Col md={2} className="d-flex align-items-center">
                                <Form.Label className="text-dark">
                                  {t("email")} :
                                </Form.Label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  value={userData.email ? userData.email : "--"}
                                  disabled
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          {/* Set fields by their roles */}
                          {(userData.role === "BUSINESS_USER" ||
                            userData.role === "DEALER" ||
                            userData.role === "USER") && (
                            <>
                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("mobilenumber")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        userData.phoneNo
                                          ? "+" +
                                            userData.countryCode +
                                            " " +
                                            userData.phoneNo
                                          : "--"
                                      }
                                      disabled
                                    />
                                  </Col>
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("city")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        userData.town ? userData.town : "--"
                                      }
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("province")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        userData.province
                                          ? userData.province
                                          : "--"
                                      }
                                      disabled
                                    />
                                  </Col>
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("taxcode")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        userData.taxCode
                                          ? userData.taxCode
                                          : "--"
                                      }
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("pincode")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={userData.cap ? userData.cap : "--"}
                                      disabled
                                    />
                                  </Col>

                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("streetaddress")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        userData.address
                                          ? userData.address
                                          : "--"
                                      }
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>{" "}
                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  {userData.role !== "USER" && (
                                    <>
                                      <Col
                                        md={2}
                                        className="d-flex align-items-center"
                                      >
                                        <Form.Label className="text-dark">
                                          {t("vatnumber")} :
                                        </Form.Label>
                                      </Col>
                                      <Col md={4}>
                                        <Form.Control
                                          value={
                                            userData.vat ? userData.vat : "--"
                                          }
                                          disabled
                                        />
                                      </Col>
                                    </>
                                  )}
                                  {userData.role === "USER" && (
                                    <>
                                      <Col
                                        md={2}
                                        className="d-flex align-items-center"
                                      >
                                        <Form.Label className="text-dark">
                                          {t("walletamount")} :
                                        </Form.Label>
                                      </Col>
                                      <Col md={4}>
                                        <Form.Control
                                          value={
                                            userData.walletAmount
                                              ? `€${ReplaceFunction(
                                                  userData.walletAmount?.toFixed(
                                                    2
                                                  )
                                                )}`
                                              : 0
                                          }
                                          disabled
                                        />
                                      </Col>

                                      <Col
                                        md={2}
                                        className="d-flex align-items-center"
                                      >
                                        <Form.Label className="text-dark">
                                          {t("syncedwalletamount")} :
                                        </Form.Label>
                                      </Col>
                                      <Col md={4}>
                                        <Form.Control
                                          value={
                                            userData.syncedWalletAmount
                                              ? `€${ReplaceFunction(
                                                  userData.syncedWalletAmount?.toFixed(
                                                    2
                                                  )
                                                )}`
                                              : 0
                                          }
                                          disabled
                                        />
                                      </Col>
                                    </>
                                  )}
                                  {userData.role !== "USER" && (
                                    <>
                                      <Col
                                        md={2}
                                        className="d-flex align-items-center"
                                      >
                                        <Form.Label className="text-dark">
                                          {t("eleinvoicecode")} :
                                        </Form.Label>
                                      </Col>
                                      <Col md={4}>
                                        <Form.Control
                                          value={
                                            userData.einvoicing
                                              ? userData.einvoicing
                                              : "--"
                                          }
                                          disabled
                                        />
                                      </Col>
                                    </>
                                  )}
                                </Row>
                              </FormGroup>
                              {userData.role === "USER" && (
                                <>
                                  <FormGroup className="form-group">
                                    <Row className=" row-sm">
                                      <Col
                                        md={2}
                                        className="d-flex align-items-center"
                                      >
                                        <Form.Label className="text-dark">
                                          {t("cashbacamountinpr")} :
                                        </Form.Label>
                                      </Col>
                                      <Col md={4}>
                                        <Form.Control
                                          value={
                                            userData.cashbackAmountInPr + "%"
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col
                                        md={2}
                                        className="d-flex align-items-center"
                                      >
                                        <Form.Label className="text-dark">
                                          {t("servicefeeapplied")} :
                                        </Form.Label>
                                      </Col>
                                      <Col md={4}>
                                        <Form.Control
                                          value={
                                            userData.serviceFeeApplied
                                              ? "Yes"
                                              : "No"
                                          }
                                          disabled
                                        />
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup className="form-group">
                                    <Row className=" row-sm">
                                      <Col
                                        md={2}
                                        className="d-flex align-items-center"
                                      >
                                        <Form.Label className="text-dark">
                                          {t("cashbackamount")} :
                                        </Form.Label>
                                      </Col>
                                      <Col md={4}>
                                        <Form.Control
                                          value={
                                            userData.cashbackAmount
                                              ? `€${ReplaceFunction(
                                                  userData.cashbackAmount?.toFixed(
                                                    2
                                                  )
                                                )}`
                                              : 0
                                          }
                                          disabled
                                        />
                                      </Col>
                                      <Col
                                        md={2}
                                        className="d-flex align-items-center"
                                      >
                                        <Form.Label className="text-dark">
                                          {t("deleted")} :
                                        </Form.Label>
                                      </Col>
                                      <Col md={4}>
                                        <Form.Control
                                          value={
                                            userData.deleted ? "Yes" : "No"
                                          }
                                          disabled
                                        />
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                </>
                              )}
                            </>
                          )}

                          {userData.role === "BUSINESS_USER" && (
                            <>
                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("companyname")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={userData.companyName}
                                      disabled
                                    />
                                  </Col>

                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      PEC :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={userData.pec ? userData.pec : "--"}
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("walletamount")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        userData.walletAmount
                                          ? `€${ReplaceFunction(
                                              userData.walletAmount?.toFixed(2)
                                            )}`
                                          : 0
                                      }
                                      disabled
                                    />
                                  </Col>

                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("syncedwalletamount")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        userData.syncedWalletAmount
                                          ? `€${ReplaceFunction(
                                              userData.syncedWalletAmount?.toFixed(
                                                2
                                              )
                                            )}`
                                          : 0
                                      }
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("monthlyfeeprice")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        userData.monthlyFeePrice
                                          ? `€${ReplaceFunction(
                                              userData.monthlyFeePrice?.toFixed(
                                                2
                                              )
                                            )}`
                                          : userData.monthlyFeePrice
                                      }
                                      disabled
                                    />
                                  </Col>

                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("servicefeeapplied")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        userData.serviceFeeApplied
                                          ? "Yes"
                                          : "No"
                                      }
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("cashbackamount")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        userData.cashbackAmount
                                          ? `€${ReplaceFunction(
                                              userData.cashbackAmount?.toFixed(
                                                2
                                              )
                                            )}`
                                          : userData.cashbackAmount
                                      }
                                      disabled
                                    />
                                  </Col>

                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("cashbacamountinpr")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        userData.cashbackAmountInPr
                                          ? userData.cashbackAmountInPr + "%"
                                          : 0
                                      }
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>

                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("expirationdate")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={
                                        userData.expiration
                                          ? moment(userData.expiration).format(
                                              "DD/MM/YYYY HH:mm:ss"
                                            )
                                          : "--"
                                      }
                                      disabled
                                    />
                                  </Col>

                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("childuserlimit")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={userData.childLimit}
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                            </>
                          )}
                          {userData.role === "CHILD_USER" && (
                            <>
                              <FormGroup className="form-group">
                                <Row className=" row-sm">
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("parentbusinessuser")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={userNames ? userNames : "--"}
                                      disabled
                                    />
                                  </Col>
                                  <Col
                                    md={2}
                                    className="d-flex align-items-center"
                                  >
                                    <Form.Label className="text-dark">
                                      {t("companyname")} :
                                    </Form.Label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      value={companyName ? companyName : "--"}
                                      disabled
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <div className="d-none">
            <TransactionSlip />
          </div>

          {/* data table by their role */}
          {(userData.role === "BUSINESS_USER" ||
            userData.role === "DEALER") && (
            <Col md={12}>
              <Card>
                <div className="p-3">
                  <h5 className="mb-3 text-dark">
                    {userData.role === "BUSINESS_USER"
                      ? t("childuser")
                      : t("historyofuserrecharges")}
                  </h5>

                  <div className="d-flex mb-3">
                    {userData.role === "DEALER" && (
                      <>
                        <Col sm={4} md={4} lg={4} xl={3} xxl={2}>
                          {/* <DateRangePicker
                            initialSettings={{
                              startDate: moment(new Date())
                                .subtract(1, "months")
                                .format("MM/DD/YYYY"),
                              endDate: moment(new Date()).format("MM/DD/YYYY"),
                              ranges: RANGES,
                            }}
                            onApply={setDates}
                          >
                            <input
                              type="text"
                              value={
                                moment(historyStartDate).format("DD/MM/YYYY") +
                                " - " +
                                moment(historyEndDate).format("DD/MM/YYYY")
                              }
                              className="form-control"
                            />
                          </DateRangePicker> */}
                          <DateRangePicker
                            initialSettings={{
                              startDate: moment(new Date()).format(
                                "DD/MM/YYYY"
                              ),
                              endDate: moment(new Date()).format("DD/MM/YYYY"),
                              ranges: RANGES,
                              autoUpdateInput: false,
                              locale: {
                                format: "DD/MM/YYYY", // Specify the desired format
                              },
                            }}
                            onApply={setHistoryDates}
                            onCancel={cancelHistoryDate}
                            onHide={cancelHistoryDate}
                          >
                            {historyStartDate && historyEndDate ? (
                              <input
                                type="text"
                                placeholder={t("selectperiodvalidity")}
                                value={
                                  historyStartDate && historyEndDate
                                    ? `${moment(historyStartDate).format(
                                        "DD/MM/YYYY"
                                      )} - ${moment(historyEndDate).format(
                                        "DD/MM/YYYY"
                                      )}`
                                    : ""
                                }
                                defaultValue=""
                                onChange={(startDate, endDate) =>
                                  handleHistoryDateChange(startDate, endDate)
                                }
                                className="form-control"
                              />
                            ) : (
                              <input
                                type="text"
                                placeholder={t("selectperiodvalidity")}
                                defaultValue=""
                                className="form-control"
                              />
                            )}
                          </DateRangePicker>
                        </Col>
                        <Col>
                          <div className="d-flex d-lg-flex add-search ms-auto">
                            <div className="d-flex flex-grow-1 flex-md-grow-0 flex-lg-grow-0 align-items-center md-form mt-2 mt-md-auto mt-lg-auto ms-auto me-2">
                              <div className="input-group">
                                <input
                                  type="search"
                                  className="form-control form-control-md py-3"
                                  value={historySearchString}
                                  onChange={historyHandlesearch}
                                  placeholder={t("search") + "..."}
                                />
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fa fa-search"></i>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <button
                              className="btn btn-primary btn-sm me-2"
                              onClick={handleDownloadExcel}
                            >
                              <i className="fas fa-upload"></i> {t("excel")}
                            </button>

                            <button
                              onClick={exportPDF}
                              className="btn btn-primary btn-sm"
                            >
                              <i class="fas fa-file-pdf"></i> PDF
                            </button>
                          </div>
                        </Col>
                      </>
                    )}
                  </div>
                  <DataTable
                    title="History Of User Recharges"
                    columns={columns}
                    data={data}
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={true}
                    striped={true}
                    center={true}
                    persistTableHead
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={historyDataPerPage}
                    onChangeRowsPerPage={(page) => setHistoryDataPerPage(page)}
                    onChangePage={historyHandlePageChange}
                    highlightOnHover
                    customStyles={userData.role === "DEALER" && CUSTOM_STYLES}
                  />
                </div>
              </Card>
            </Col>
          )}

          {userData.role === "BUSINESS_USER" && (
            <Col md={12} className="mt-3">
              <Card>
                <div className="p-3">
                  <h5 className="mb-3 text-dark">{t("parkhistory")}</h5>

                  <Row>
                    <Col md={2}>
                      <Form.Group className="form-group">
                        {/* <DateRangePicker
                          initialSettings={{
                            startDate: moment(new Date())
                              .subtract(1, "months")
                              .format("MM/DD/YYYY"),
                            endDate: moment(new Date()).format("MM/DD/YYYY"),
                            ranges: RANGES,
                          }}
                          onApply={setParkingDates}
                        >
                          <input
                            type="text"
                            value={
                              moment(startDate).format("DD/MM/YYYY") +
                              " - " +
                              moment(endDate).format("DD/MM/YYYY")
                            }
                            className="form-control"
                          />
                        </DateRangePicker> */}
                        <DateRangePicker
                          initialSettings={{
                            startDate: moment(new Date()).format("DD/MM/YYYY"),
                            endDate: moment(new Date()).format("DD/MM/YYYY"),
                            ranges: RANGES,
                            autoUpdateInput: false,
                            locale: {
                              format: "DD/MM/YYYY", // Specify the desired format
                            },
                          }}
                          onApply={setDates}
                          onCancel={cancelDate}
                          onHide={cancelDate}
                        >
                          {startDate && endDate ? (
                            <input
                              type="text"
                              placeholder={t("selectperiodvalidity")}
                              value={
                                startDate && endDate
                                  ? `${moment(startDate).format(
                                      "DD/MM/YYYY"
                                    )} - ${moment(endDate).format(
                                      "DD/MM/YYYY"
                                    )}`
                                  : ""
                              }
                              defaultValue=""
                              onChange={(startDate, endDate) =>
                                handleDateChange(startDate, endDate)
                              }
                              className="form-control"
                            />
                          ) : (
                            <input
                              type="text"
                              placeholder={t("selectperiodvalidity")}
                              defaultValue=""
                              className="form-control"
                            />
                          )}
                        </DateRangePicker>
                      </Form.Group>
                    </Col>
                    <Col md={6}></Col>
                    <Col
                      md={4}
                      className="d-flex mb-2 justify-content-end justify-content-md-start justify-content-lg-start"
                    >
                      {/* <div className="d-flex mt-2"> */}
                      <div className="d-flex align-items-center md-form mt-auto ms-auto mb-2 me-3">
                        <div className="input-group">
                          <input
                            type="search"
                            className="form-control form-control-md py-3"
                            value={searchString}
                            onChange={handlesearch}
                            placeholder={t("search") + "..."}
                          />
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-search"></i>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div>
                        <button
                          className="btn btn-primary ms-2 btn-sm p-2"
                          onClick={handleParkHistoryDownloadExcel}
                        >
                          <i class="fa fa-download"></i> {t("excel")}
                        </button>
                      </div>
                      <div>
                        <button
                          className="btn btn-primary ms-2 btn-sm p-2"
                          onClick={exportParkHistoryPDF}
                        >
                          <i class="fas fa-file-pdf"></i> PDF
                        </button>
                      </div>
                      {/* </div> */}
                    </Col>
                  </Row>
                  <DataTable
                    title="Park History"
                    columns={ParkColumns}
                    data={parkingData}
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    striped={true}
                    center={true}
                    persistTableHead
                    pagination
                    paginationServer
                    paginationTotalRows={parkingTotalRows}
                    paginationPerPage={dataPerPage}
                    onChangeRowsPerPage={(page) => setDataPerPage(page)}
                    onChangePage={handlePageChange}
                    highlightOnHover
                  />
                </div>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    </Fragment>
  );
};

export default withLoader(Profie);
